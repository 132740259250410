<!-- Copyright © 2024 Insurance Services Office, Inc. All rights reserved -->

<div class="item root-menu-item" style="margin-top: 4px;">
    <i class="fa-solid fa-file"></i> <a routerLink="/file-explorer">File Explorer</a>
</div>
<mat-accordion class="">
    <mat-expansion-panel class="item" [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <i class="fa-solid fa-gear "></i> {{ Constants.LEFT_MENU_Configuration }}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-panel-title class="second">
            <i class="fa-solid fa-receipt"></i><a routerLink="/recipe/recipe-list">{{ Constants.COMMON_Recipe }}</a>
        </mat-panel-title>
        <br />
        <mat-panel-title class="second">
            <i class="fa-solid fa-users-gear"></i><a routerLink="/customer/customer-list">{{ Constants.COMMON_Customer
                }}</a>
        </mat-panel-title>
        <br />
        <mat-panel-title class="second">
            <i class="fa-solid fa-table"></i><a routerLink="/dataset/target-dataset">{{ Constants.COMMON_Dataset }}</a>
        </mat-panel-title>
    </mat-expansion-panel>
</mat-accordion>
<mat-accordion class="">
    <mat-expansion-panel class="item" [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <i class="fa-solid fa-gear "></i> {{ Constants.LEFT_MENU_Reports }}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-panel-title class="second">
            <i class="fa-solid fa-receipt"></i><a routerLink="/reports/dashboard">{{ Constants.COMMON_Dashboard }}</a>
        </mat-panel-title>
        <br />
        <mat-panel-title class="second">
            <i class="fa-solid fa-users-gear"></i><a routerLink="/reports/logs">{{ Constants.LEFT_MENU_Logs }}</a>
        </mat-panel-title>
        <br />
        <mat-panel-title class="second">
            <i class="fa-solid fa-table"></i><a routerLink="/reports/results">{{ Constants.LEFT_MENU_Results }}</a>
        </mat-panel-title>
    </mat-expansion-panel>
</mat-accordion>