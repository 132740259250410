<!-- Copyright © 2024 Insurance Services Office, Inc. All rights reserved -->

<div class="top-panel-header">
    <h2 class="mb-3"> {{ label }}</h2>
    <div class="button-panel">
        <ng-container *ngIf="displaySave">
            <button type="button" (click)="saveForm()" [disabled]="formStatus"
              class="btn btn-light btn-sm without-focus" title="Save">
                <i class="fa-solid fa-save fa-xl"></i>
            </button>
        </ng-container>

        <button type="button" (click)="cancelForm()" class="btn btn-light btn-sm without-focus" title="Cancel">
            <i class="fa-solid fa-cancel fa-xl"></i>
        </button>
    </div>
</div>
