
<div class="ag-grid-top-panel">
    <button type="button" title="Add new" (click)="addRow()" *ngIf="this.config?.addUrl != null"
    class="btn btn-sm btn-light without-focus" [disabled]="config?.disableAdd">
        <i class="fa-solid fa-circle-plus fa-xl"></i>
    </button>

    <button type="button" class="btn btn-light without-focus btn-sm" title="Refresh" (click)="refresh()"
    [disabled]="config?.disableRefresh">
        <i class="fa-solid fa-arrows-rotate fa-xl"></i>
    </button>
</div>

<div class="grid-container">
    <ag-grid-angular
        class="ag-theme-alpine ag-grid-withoutTopBorder full-width-grid"
        [columnDefs]="columnDefs"
        [rowData]="rowData"
        (gridReady)="onGridReady($event)"
        [gridOptions]="gridOptions"
        ag-grid-resize>
    </ag-grid-angular>
</div>
