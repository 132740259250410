import { Component } from '@angular/core';
import { Constants } from 'src/app/constants/app.constants'

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent {
  Constants = Constants;
}
