import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, throwError, timer, of } from 'rxjs';
import { finalize, map, mergeMap, retryWhen, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Config } from 'src/app/models/config';
import { AppService } from '../../app.service';
import { LoaderService, RequestLoaderDto } from '../loader.service';
import { ToastService } from '@uw-verisk/common-ui';

@Injectable({
    providedIn: 'root'
})

export class HttpBaseService {
    apiUrl: string;
    user: string;
    readonly retryAtempt = 0;

    constructor(private http: HttpClient, private appService: AppService, private loader: LoaderService, private toastService: ToastService) {
        this.apiUrl = environment.datadropApiUrl;
        this.user = Config.username;
    }

    getIdentity(): string {
        return this.appService.readConfig().userINumber + "@verisk.com";
    }

    getAllPages<T>(relativeUrl: string, pageSize: number = environment.numberItemsInPage, params: HttpParams = null, headers: any = null): any {
        headers = { ...headers, ...{ 'identity': this.getIdentity() } };
        const requestLoaderDto = new RequestLoaderDto(relativeUrl);
        this.loader.requestStart(requestLoaderDto);

        const createRequestForAllPages = (data: any) => {
            this.loader.requestFinished(requestLoaderDto);
            const requests = [];
            requests.push(of(data));
            for (let pageIndex = 2; pageIndex <= data.totalPages; pageIndex++) {
                const request = this.http.get<T>(this.apiUrl + relativeUrl + this.getPageParam(pageIndex, pageSize), {
                    headers: headers,
                    params: params,
                    responseType: 'json'
                });
                requests.push(request);
            }

            const requestFork = new RequestLoaderDto(relativeUrl + " +requestFork");
            this.loader.requestStart(requestFork);


            const joinAllDataFromForkJoin = (x: any) => {
                if (x.length == 0)
                    return;
                const acc = [];
                for (const y of x) {
                    acc.push(...y.data);
                }
                this.loader.requestFinished(requestFork);
                return {
                    data: acc,
                    pageSize: x[0].pageSize,
                    totalCount: x[0].totalCount,
                    totalPages: x[0].totalPages
                };
            };
            return forkJoin(requests)
                .pipe(
                    map(joinAllDataFromForkJoin),
                    retryWhen(
                        this.retryStategy(() => { this.loader.requestFinished(requestFork) })
                    ),
                    finalize(() => {
                        this.loader.requestFinished(requestLoaderDto);
                    })
                );
        }

        const resRequest = this.http.get<T>(this.apiUrl + relativeUrl + this.getPageParam(1, pageSize), {
            headers: headers,
            params: params,
            responseType: 'json'
        }).pipe(
            switchMap(createRequestForAllPages),
            retryWhen(
                this.retryStategy(() => {
                    this.loader.requestFinished(requestLoaderDto);
                })
            ),
            finalize(() => {
                this.loader.requestFinished(requestLoaderDto);
            })
        );
        return resRequest;
    }

    getPageParam(pageNumber: number, pageSize: number) {
        return `?PageNumber=${pageNumber}&pageSize=${pageSize}`;
    }

    get<T>(relativeUrl, params: HttpParams = null, headers: any = null): Observable<T> {
        headers = { ...headers, ...{ 'identity': this.getIdentity() } };
        const request = this.http.get<T>(this.apiUrl + relativeUrl, {
            headers: headers,
            params: params,
            responseType: 'json'
        })
        return this.addBaseHttpStategy(request, relativeUrl);
    }

    create<T>(relativeUrl, body: any, params: HttpParams = null, headers: any = null): Observable<T> {
        headers = { ...headers, ...{ 'identity': this.getIdentity() } };
        const request = this.http.post<T>(this.apiUrl + relativeUrl,
            body,
            {
                headers: headers,
                params: params,
                responseType: 'json'
            });
        return this.addBaseHttpStategy(request, relativeUrl);
    }

    update<T>(relativeUrl, body: any, params: HttpParams = null, headers: any = null): Observable<T> {
        headers = { ...headers, ...{ 'identity': this.getIdentity() } };
        const request = this.http.put<T>(this.apiUrl + relativeUrl,
            body,
            {
                headers: headers,
                params: params,
                responseType: 'json'
            });
        return this.addBaseHttpStategy(request, relativeUrl);
    }

    patch<T>(relativeUrl, body: any, params: HttpParams = null, headers: any = null): Observable<T> {
        headers = { ...headers, ...{ 'identity': this.getIdentity() } };
        const request = this.http.patch<T>(this.apiUrl + relativeUrl,
            body,
            {
                headers: headers,
                params: params,
                responseType: 'json'
            });
        return this.addBaseHttpStategy(request, relativeUrl);
    }

    delete<T>(relativeUrl, params: HttpParams = null, headers: any = null): Observable<T> {
        headers = { ...headers, ...{ 'identity': this.getIdentity() } };
        const request = this.http.delete<T>(this.apiUrl + relativeUrl,
            {
                headers: headers,
                params: params,
                responseType: 'json'
            });
        return this.addBaseHttpStategy(request, relativeUrl);
    }

    runJob<T>(relativeUrl, body: any, params: HttpParams = null, headers: any = null): Observable<T> {
        headers = { ...headers, ...{ 'identity': this.getIdentity() } };
        const options: {
            headers?: HttpHeaders,
            observe?: 'body',
            params?: HttpParams,
            reportProgress?: boolean,
            responseType: 'text',
            withCredentials?: boolean
        } = {
            headers: headers,
            params: params,
            responseType: 'text'
        };
        const request = this.http.post(this.apiUrl + relativeUrl,
            body,
            options);
        return this.addBaseHttpStategy(request, relativeUrl);
    }

    private addBaseHttpStategy(request: Observable<any>, description: string): any {
        const requestLoaderDto = new RequestLoaderDto(description);
        this.loader.requestStart(requestLoaderDto);
        return request.pipe(
            map((res: any) => {
                this.loader.requestFinished(requestLoaderDto);
                return res;
            }),
            retryWhen(
                this.retryStategy(() => { this.loader.requestFinished(requestLoaderDto) })
            ),
            finalize(() => {
                this.loader.requestFinished(requestLoaderDto);
            })
        );
    }

    private retryStategy = (cbFinis) => (attempts: Observable<any>) => {
        const deleyTable = [10, 15, 20];
        return attempts.pipe(
            mergeMap((error, index) => {
                const message = error.message ?? error;
                if (message.includes('400')) {
                    return throwError(error);
                }
                if (index < deleyTable.length) {
                    return timer(deleyTable[index] * 1000);
                } else {
                    this.toastService.error("Finish  " + message);
                    return throwError("retry Stategy fail request");
                }
            }),
            finalize(() => {
                cbFinis();
            })
        );
    };

}
