import { AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
  selector: 'ng-select'
})
export class NgSelectTooltipDirective implements AfterViewInit, OnDestroy {
  private observer: MutationObserver;
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.observer = new MutationObserver(() => {
      this.addTooltips();
    });

    this.observer.observe(this.el.nativeElement, {
      childList: true,
      subtree: true
    });

    this.addTooltips();
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }

  private addTooltips() {

    const options = this.el.nativeElement.querySelectorAll('.ng-option');
    options.forEach((option: HTMLElement) => {
      if (option.scrollWidth > option.clientWidth) {
        option.title = option.textContent?.trim();
      } else {
        option.title = '';
      }
    });

    const selectedOption = this.el.nativeElement.querySelector('.ng-select-container .ng-value');
    if (selectedOption && selectedOption.scrollWidth > selectedOption.clientWidth) {
      const ngValueLabel = selectedOption.querySelector('.ng-value-label');
      const textContent = ngValueLabel?.textContent?.trim();
      this.el.nativeElement.title = textContent;
    } else {
      this.el.nativeElement.title = '';
    }
  }
}