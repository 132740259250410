<!-- Copyright © 2024 Insurance Services Office, Inc. All rights reserved -->

<div class="filter-panel">
  <div class="filter-item">
    <div class="input-label">{{ Constants.COMMON_Customer }}</div>
    <ng-select [formControl]="selectedCustomerId" ngDefaultControl placeholder="Select Customer"
    (change)="onSelectCustomer($event)">
      <ng-option *ngFor="let customer of customerList" [value]="customer.id">{{
        customer.customerName
      }}</ng-option>
    </ng-select>
  </div>

  <div class="filter-item">
    <div class="input-label">{{ Constants.RECIPE_IDENTIFICATION_Source_System }}</div>
    <ng-select
      [formControl]="selectedSourceSystemId"
      placeholder="Select Source System"
      (change)="onSelectSourceSystem($event)"
    >
      <ng-option
        *ngFor="let ss of filteredSourceSystemList"
        [value]="ss.sourceSystemId"
        >{{ ss.sourceSystemName }}</ng-option
      >
    </ng-select>
  </div>

  <div class="filter-item">
    <div class="input-label">{{ Constants.COMMON_Product }}</div>
    <ng-select [formControl]="selectedProductId" placeholder="Select Product"
    (change)="onSelectProduct($event)">
      <ng-option
        *ngFor="let product of filteredProductList"
        [value]="product.productId"
        >{{ product.productName }}</ng-option
      >
    </ng-select>
  </div>

  <div class="filter-item">
    <div class="input-label">{{ Constants.COMMON_LOB }}</div>
    <ng-select [formControl]="selectedLobId" placeholder="Select LOB"
    (change)="onSelectLob($event)">
      <ng-option *ngFor="let lob of filteredLobList" [value]="lob.lobId">{{
        lob.lobName
      }}</ng-option>
    </ng-select>
  </div>

  <div class="filter-item" *ngIf="showDatasetList">
    <div class="input-label">{{ Constants.COMMON_Dataset }}</div>
    <ng-select [formControl]="selectedDatasetId" placeholder="Select Dataset"
    (change)="onSelectDataset($event)"
    >
      <ng-option
        *ngFor="let dataset of filteredDataSet"
        [value]="dataset.datasetId"
        >{{ dataset.datasetName }}</ng-option
      >
    </ng-select>
  </div>
</div>
