export abstract class Constants  {
  static readonly YES = "Yes";
  static readonly NO = "No";

  static readonly COMMON_Recipe = "Recipe";
  static readonly COMMON_Customer = "Customer";
  static readonly COMMON_Dataset = "Dataset";
  static readonly COMMON_Dashboard = "Dashboard";
  static readonly COMMON_Product = "Product";
  static readonly COMMON_Lob = "Lob";
  static readonly COMMON_LOB = "LOB";
  static readonly COMMON_Dataset_Name = "Dataset Name";

  static readonly LEFT_MENU_Configuration = "Configuration";
  static readonly LEFT_MENU_Logs = "Logs";
  static readonly LEFT_MENU_Results = "Results";
  static readonly LEFT_MENU_Reports = "Reports";

  static readonly CUSTOMER_ITEM_Customer_Name = "Customer Name";
  static readonly CUSTOMER_ITEM_Customer_ISO = "Customer ISO Company Code";
  static readonly CUSTOMER_ITEM_Email = "Email";
  static readonly CUSTOMER_ITEM_Phone = "Phone";
  static readonly CUSTOMER_ITEM_Address = "Address";
  static readonly CUSTOMER_ITEM_Scopes = "Scopes";

  static readonly CUSTOMER_SCOPE_Source_System = "Source System Name";

  static readonly DATASET_ITEM_Customer_Name = "Customer Name";
  static readonly DATASET_ITEM_Source_System = "Source System";
  static readonly DATASET_ITEM_Dataset_Description = "Dataset Description";
  static readonly DATASET_ITEM_File_Delimiter = "File Delimiter";
  static readonly DATASET_ITEM_File_Type = "File Type";
  static readonly DATASET_ITEM_Filename_Pattern = "Filename Pattern";
  static readonly DATASET_ITEM_Number_Files = "Number of Files";
  static readonly DATASET_ITEM_Dataset_Attributes = "Dataset Attributes";

  static readonly TARGET_DATASET_Description = "Description";
  static readonly TARGET_DATASET_Attributes = "Attributes";

  static readonly RECIPE_MAP_TargetColumn = "Target Column";
  static readonly RECIPE_MAP_SourceColumn = "Source Column";
  static readonly RECIPE_MAP_Notes = "Notes";

  static readonly COMMENT_Comments = "Comments";
  static readonly COMMENT_New_Comment = "New Comment";
  static readonly COMMENT_Save = "Save Comment";
  static readonly COMMENT_Clear = "Clear";

  static readonly STEP_LIST_Recipe = "Recipe";
  static readonly STEP_LIST_Edit = "Edit";
  static readonly STEP_LIST_Delete = "Delete";

  static readonly RECIPE_IDENTIFICATION_Source_System = "Source System";
}
